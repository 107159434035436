/* 
	============================================================
	FRAMEWORK PROPRIO MINIMO
	DATA            VERSION        AUTHOR
	03-04-2020      1.0            GEOVANE FILGUEIRA DE ALMEIDA
	DOCUMENTAÇÃO 
	1.0
	MAIS INFORMAÇÕES      @geovanefilgueira
	

	ALTURA --------------OK
	LARGURA--------------OK
	MARGINS E PADDINGS---OK
	TEXTOS---------------OK
	FORMULARIOS
	EFEITOS ESPECIAIS, SOMBRA ETC..
	
	INFORMAÇÕES 
	A ALTURA É DEFINIDA COM O PREFIXO (.a10) = altura em 10em
	A LARGURA É DEFINIDA AM (.l10) = largura em 10%

*/

/* ============================================================ 
	TUDO 
	
}
	
}

*/
/*
html:not(img):not(video):not(.all-color-critical-elements) { 
	background: black;
	filter: invert(1) hue-rotate(180deg);
}
*/
body{
	
	
	background: #F6F6F6;
	/*
	filter: brightness(70%) hue-rotate(183deg) saturate(676%) invert(85%);
	-webkit-filter: brightness(70%) hue-rotate(183deg) saturate(676%) invert(85%);
	-moz-filter: brightness(70%) hue-rotate(183deg) saturate(676%) invert(85%);
	*/
	/*
	filter: brightness(105%) hue-rotate(171deg) saturate(168%) invert(86%);
	-webkit-filter: brightness(105%) hue-rotate(171deg) saturate(168%) invert(86%);
	-moz-filter: brightness(105%) hue-rotate(171deg) saturate(168%) invert(86%);
	*/
}

.teste{
	background-image: url(https://img.freepik.com/vetores-premium/fast-food-amarelo-e-laranja-sem-costura-padrao_554517-531.jpg?w=740);
	background-position: center;
	background-size: 140px;
	background-repeat: repeat;
	background-attachment:fixed;
}

html{background: #fff; scroll-behavior: smooth;}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
 
*:focus {
	outline: none;
  }
button:focus {
	outline: none;
  }
pre {
	white-space: pre-wrap;
	word-wrap: break-word;
  }
*{padding: 0px; font-size: 16px; margin:0px; color:#414141;list-style: none; font-family: sans-serif; text-decoration:none; word-wrap: break-word; line-height: 1;
/* global.css */

	outline: none;
	-webkit-tap-highlight-color: transparent;
	user-select: none;
  
  
}
.App{
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
} 
::-webkit-input-placeholder {
	color:#686868;
 }
 
 :-moz-placeholder { /* Firefox 18- */
	color:#686868;  
 }
 
 ::-moz-placeholder {  /* Firefox 19+ */
	color:#686868;  
 }
 
 :-ms-input-placeholder {  
	color:#686868;  
 }

 ::-webkit-scrollbar {
	width: 8px;
	height:8px;
	
   }
   ::-webkit-scrollbar-thumb {
	background: linear-gradient(13deg, #f8f8fa00 14%,#f8f8fa00 64%);
	border-radius: 10px;
   }
   ::-webkit-scrollbar-thumb:hover{
	background: linear-gradient(13deg, #f8f8fa00 14%,#f8f8fa00 64%);
   }
   ::-webkit-scrollbar-track{
	background: #f8f8fa00;
	border-radius: 10px;
	box-shadow: inset 7px 10px 12px #f8f8fa00;
   }


   
/* Remove bordas do calendário */
.react-calendar {
    border: none !important;
    /*box-shadow: none !important;*/
	margin-bottom: 25px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
}

.react-calendar__tile {
    border: none !important;
}

/* Remover borda na parte de navegação e nos cabeçalhos de dias da semana */
.react-calendar__month-view__weekdays,
.react-calendar__navigation {
    border: none !important;
    box-shadow: none !important;
}

/* ============================================================ 
	ALTURA


*/
#munuIcons > a > button:hover{
	background: #1C1C1C;
}

.mx45{max-height: 45px;}

.he15{height: 15vh;}
.he25{height: 25vh;}
.he20{height: 20vh;}
.he30{height: 30vh;}
.he50{height: 50vh;}
.he60{height: 60vh;}
.he100{height: 100vh;}

.h2{min-height: 2vh;}
.h4{min-height: 4vh;}
.h5{min-height: 5vh;}
.h5t{min-height: 5vh;}
.h6{min-height: 6vh;}
.h7{min-height: 7vh;}
.h8{min-height: 8vh;}
.h10{min-height: 10vh;}
.h13{min-height: 13vh;}
.h15{min-height: 15vh;}
.h17{min-height: 17vh;}
.h20{min-height: 20vh;}
.h25{min-height: 25vh;}
.h28{min-height: 28vh;}
.h31{min-height: 31vh;}
.h33{min-height: 33vh;}
.h35{min-height: 35vh;}
.h40{min-height: 40vh;}
.h50{min-height: 50vh;}
.h60{min-height: 60vh;}
.h70{min-height: 70vh;}
.h75{min-height: 75vh;}
.h76{min-height: 76vh;}
.h80{min-height: 80vh;}
.h83{min-height: 83vh;}
.h85{min-height: 85vh;}
.h88{min-height: 88vh;}
.h90{min-height: 90vh;}
.h95{min-height: 95vh;}
.h97{min-height: 97vh;}
.h100{min-height: 100vh;}
.h105{min-height: 105vh;}
.h110{min-height: 110vh;}
.h150{min-height: 150vh;}

.he40{height: 40vh;}
.he60{height: 60vh;}
.he65{height: 65vh;}
.he80{height: 80vh;}

.h70px{width: 70px;}

/* ============================================================ 
	LARGURA
*/
.we40px{width: 50px;}

.we30{width: 30%;}
.we40{width: 40%;}
.wm10{min-width: 10%;}

.we2200{width: 2200px;}
.we120px{width: 120px;}
.we190px{width: 190px;}

.wmax50{max-width: 50px;}
.wmax80{max-width: 80px;}
.wmax600{max-width: 600px;}
.wmax1200{max-width: 1200px;}

.wmin1200{min-width: 1800px;  }

.w2{width: 2%;}
.w4{width: 4%;}
.w5{width: 5%;}
.w7{width: 7%;}
.w8{width: 8%;}
.w9{width: 9%;}
.w10{width: 10%;}
.w16{width: 16%;}
.w14{width: 14%;}
.w15{width: 15%;}
.w17{width: 17%;}
.w18{width: 18%;}
.w20{width: 20%;}
.w24{width: 24%;}
.w25{width: 25%;}
.w28{width: 28%;}
.w30{width: 30%;}
.w31{width: 31%;}
.w32{width: 32%;}
.w33{width: 33%;}
.w35{width: 35%;}
.w40{width: 40%;}
.w45{width: 45%;}
.w49{width: 49%;}
.w50{width: 50%;}
.w55{width: 55%;}
.w60{width: 60%;}
.w65{width: 65%;}
.w66{width: 66%;}
.w70{width: 70%;}
.w75{width: 75%;}
.w79{width: 79%;}
.w80{width: 80%;}
.w85{width: 85%;}
.w83{width: 83%;}
.w90{width: 90%;}
.w95{width: 95%;}
.w97{width: 97%;}
.w100{width: 100%;}
/* ============================================================ 
	MARGINS E PADDINGS
*/
.p0{padding: 0;}
.p2{padding: 2px;}
.p5{padding: 5px;}
.p7{padding: 7px;}
.p10{padding: 10px;}
.p14{padding: 14px;}
.p20{padding: 20px;}
.p30{padding: 30px;}
.p40{padding: 40px;}
.p50{padding: 50px;}
.p60{padding: 60px;}
.p80{padding: 80px;}
.p90{padding: 90px;}
.p100{padding: 1000px;}

.pcb3{padding: 3px 0;}
.pcb7{padding: 7px 0;}
.pcb10{padding: 10px 0;}
.pc10l5{padding: 8px 4px;}
.plb10{padding: 0 10px;}
.plb4{padding: 0 4px;}

.pcb20{padding: 20px 0;}
.pcb25{padding: 0 0 10vh 0;}
.pcb50{padding: 50px 0;}
.pc10b0{padding: 10px 0 0 0;}

.phori{padding: 30px 0 50px 0;}

.pb10{padding: 0 0 10vh 0;}

.m0{margin: 0px;}
.ma2{margin:2px 0;}
.m3pc{margin: 6px 0.6%;}
.m5{margin:5px;}
.m10{margin: 10px;}
.m15{margin: 15px;}
.ma5{margin:5px 0;}
.ml5{margin:0 5px;}
.ml10{margin:0 10px;}


.ma5b{margin:0 0 5px 0;}

.ma10b0{margin:10px 0;}
.ma20b0{margin:20px 0;}
.ma25b0{margin:25px 0;}
.ma50b0{margin: 50px 0;}

.ma10vhb0{margin: 10vh 0;}

.mac0b10vh{margin:5vh 0 10vh 0;}

.ma50b15{margin: 50px 15px;}

.mac0l8{margin: 0 8px;}
.ma15b0{margin:15px 0;}
/* ============================================================ 
	TEXTOS
*/
.font-slogan{
color: #fff;
font-size: 2em;;
}

h1,h2,h3,h4,h5,h6{
	color: #3c3c3c;
	font-family: 'Montserrat', sans-serif;
	/*padding: 5px 0;*/
	font-weight: 500;
	
}
.montserrat{
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
}
.roboto{
	font-family: 'Roboto',sans-serif;
	font-style: normal;
	font-weight: 300;
	line-height: 40px;
}
/*
.lobister{font-family: 'Lobster', cursive;;}
*/
h1{
	font-size: 3.2em;
	font-family: sans-serif;
	font-style: normal;
	font-weight: 900;
	line-height: 50px;
	color: #FF8300;
	margin: 10px 0;
} /*TEXTO PRINCIPAL*/
h2{
	font-family: sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 2.8em;
	
	color: #003049;
} /*TITULO*/
h3{font-size: 3em;} /*SUBTITULO*/
h4{
	font-family: sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 2em;
	line-height: 42px;
}
h5{ 
	margin: 8px 0;
	font-size: 1.1em; 
	font-family: sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 28px;
	color: #000000;
}
h6{font-size: 1em;} /*RESERVADO PARA ESTILIZAÇÃO*/
.span{font-size: 1.0em;}/*VALORES FORMATADOS EM DINDIN ENTAO EM SPAN CLASS CORRIGI LETRAS PARA h5 */

a{color: #3c3c3c; text-decoration: none;}
a:hover{color: #E9344D; text-decoration: none;}

.c1{color: #000;}
.c2{color: #c1c1c1;}
.c3{color: #fff;}
.c4{color: #808080;}
.c5{color: #888484;}/*letras financeiro das*/
.c6{color: #4F4F4F;}/*cinza escuro*/
.c7{color:#1E90FF; }/* azul*/
.c8{color:#0B5DD3; }/* azul cor dos numeros header*/
.c9{color:#E9344D;}
.c10{color:#36e03f;}
.c11{color: #404455;}
.c12{color: #757585;}

.fw200{font-weight: 200;}
.fw300{font-weight: 300;}
.fw500{font-weight: 500;}
.fw600{font-weight: 600;}
.fw700{font-weight: 700;}

.fs09em{font-size: 0.9em;}
.fs08em{font-size: 0.8em;}
.fs06em{font-size: 0.6em;}
.fs1em{font-size: 1em;}
.fs1p1em{font-size: 1.1em;}
.fs1p3em{font-size: 1.3em;}
.fs1p5em{font-size: 1.5em;}
.fs2em{font-size: 2em;}
.fs2p5em{font-size: 2.5em;}
.fs03em{font-size: 3em;}
.fs04em{font-size: 4em;}

.jus{text-align: justify; 
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}
.cen{text-align: center;}
.ini{text-align: left;}
.fim{text-align: right;}

.fb{font-weight: 700;}
.sublinhado{text-decoration: line-through;}

.tamanhodivorig{word-wrap: break-word; }
.text-smile-weight{
	font-weight: 600;
}
/* ============================================================ 
	ALINHAMENTO
*/

.row,.column{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.menu-canvas-start{ /*ESPECIFICO*/
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: start;
}

.row{flex-direction: row; margin-right: 0px; margin-left: 0px;}
.column{flex-direction: column;}

.center{justify-content: center;}
.around{justify-content: space-around;}
.between{justify-content: space-between;}
.start{justify-content: flex-start;}
.end{justify-content: flex-end;}

.aligitensstart{align-items: start;}
.aligitenscenter{align-items: center;}

.nowrap{flex-wrap: nowrap;}

.posifixedinicio{position: fixed; top: 0px;}
.posifixedcenter{position: fixed; display: flex; justify-content: center;}
.posifixedinicio10vh{position: fixed; top: 11.5vh;}
.posifixedfim{position: fixed; bottom: 0px;}
.posifixedfim20{position: fixed; bottom: 20px;}
.posifixedfim0{position: fixed; bottom: 0px;}

.posiabstop{position: absolute; top:18vh}
.posiabstop0{position: absolute; top:0}
.posiabsrelative0{position: relative;}
.posiabsrelativebotton0{position: relative; bottom: 0;}

.abstop10left10{position: absolute; top: 10px; left: 37%;}
.abstop10le{position: absolute; top: 30vh; left: 37%;}

.left0{left: 0px;}
.rigth20{right: 20px}
.rigth0{right: 0}
.rigth5pc{right: 5%}

/* ============================================================ 
	imagens
*/
.imagem-prop{
	max-width:300px;
    max-height:250px;
    width: auto;
    height: auto;
}
/* ============================================================ 
	EFEITOS ESPECIAIS, SOMBRA ETC..
*/
.none{display: none;}

.z17{z-index: 17;}
.z16{z-index: 16;}
.z15{z-index: 15;}
.z10{z-index: 10;}
.z7{z-index: 7;}
.z5{z-index: 5;}
.z4{z-index: 4;}
.z2{z-index: 1;}
.sombra{
	box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
}
.sombra-red{
	box-shadow: 0px 0px 11px rgba(255, 32, 32, 0.767);
}
.sombra02{
	box-shadow: 0px 0px 10px #ac9f9f41;
	border-radius: 10px;
}
.sombra-small{
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
}

.cont-circ{
	border-radius: 30px;
}
.cont-circ15{
	border-radius: 15px;
}
.cont-circ3{
	border-radius: 3px;
}
.cont-circ7{
	border-radius: 7px;
}
.cont-circ10{
	border-radius: 10px;
}
.cont-circ-root{
	border-radius: 25px 25px 0 0;
}
.cont-circ-root-bottom{
	border-radius: 0 0 25px 25px;
}
.cont-circ8-left{
	border-radius:0 8px 8px 0;
}
.cont-circ8{
	border-radius: 8px;
}
.cont-circ50pc{
	border-radius: 50%;
}
.fundo-opaco{ /* elemento*/
    background-color: rgba(0, 0, 0, 0.808);
}
.fundo-opaco-light{ /* elemento*/
    background-color: rgba(65, 73, 86, 0.493);
}
.fundo-opaco-login{ /* elemento*/
    background-color: rgba(255, 255, 255, 0.692);
}
.filter-opaco{
	filter: blur(2px) brightness(48%) saturate(106%) contrast(102%);
	-webkit-filter: blur(2px) brightness(48%) saturate(106%) contrast(102%);
	-moz-filter: blur(2px) brightness(48%) saturate(106%) contrast(102%);
}

#fundo-opaco-menu{ /* CONTAINER ESPECIFICO PARA ABIR AO CLICAR EM MENU*/
	display: none;
	top:0px;
    z-index: 9;
}

.no-hover:hover {
    color: inherit;
}
.selected {
	background-color: #f0f0f0; /* Cor de fundo desejada */
	color: #081b68; /* Cor do texto ou outros elementos */
  }
.overflow{overflow-y: hidden; overflow-x:scroll;}

.overflowShow{overflow-y: hidden; overflow-x:visible; }

.overflowY{overflow-y: scroll; overflow-x:hidden;}

#mundando-cor{
	animation-name: mundando-cor;
	animation-duration:10s;
	animation-iteration-count: infinite;
}
@keyframes mundando-cor{
	0%{background:#f84d4d;}
	30%{background:#f53333;}
	90%{background:#ff9999;}
	100%{background:#f84d4d;}
}
.efectHover :hover{
    background: #8168b9;
}



.btop1{border-top: 1px #c1c1c1 solid;}
.bbot1{border-bottom: 1px #c5c5c598 solid;}

.borda1{border: 1px #CFD8DC solid;}
.borda1m2{border: 1px #cbd1e0 solid;}
.borda3{border:2px #2e2e2e solid;}

.bordasendimage{border: 3px dashed #818181; border-radius: 10px;}
.borda0{border:none;}
.bordaElements{
	box-shadow: 0px 0px 20px rgba(68, 118, 215, 0.25);
	border-radius: 3px;
}
.border-white{
	border: 1px solid #FFFFFF;
	border-radius: 15px;
}
.border-black{
	border: 1px solid #4b4b4b;
	border-radius: 15px;
}
.pp{
	cursor: pointer;
	-webkit-touch-callout: none;  /* iPhone OS, Safari */
    -webkit-user-select: none;    /* Chrome, Safari 3 */
    -khtml-user-select: none;     /* Safari 2 */
    -moz-user-select: none;       /* Firefox */
    -ms-user-select: none;        /* IE10+ */
    user-select: none;            /* Possível implementação no futuro */
    /* cursor: default; */
}

/* ============================================================ 
	MENU
*/
#btn-abrir-menu{
	width: 47px;
	height: 47px;
	border-radius: 50%;
	z-index: 17;
	cursor: pointer;
}
.tiras-menu{
	width: 48%;
	height: 4px;
	background:#1184D7;
	border-radius: 3px;
	margin:2px 0;
}
#menu{
	width: 200px;
	left: -150px;
	z-index: 3;
	background: #faf9f9;
}
.icons-menu{display: flex; margin:0 8px 0 0;}
/* ============================================================ 
	FORMULARIO E PAGINAÇÃO
*/

.pagination{min-height: 30px; min-width: 90%; display: flex; flex-direction: row; align-items: center; padding: 10px;  }
.page-item{ background: #0A63A4;  }
.page-link{background: #fff;  padding: 8px 12px; color:#007BFF;  text-decoration: none; border: 1px solid #DEE2E6;}
.page-link:hover{color:#007BFF; background:#DEE2E6;}



button,.button{ height: 6vh; margin: 0; padding: 7px 25px; background: #F3123C; border:0; border-radius: 30px; cursor: pointer;  color: #ffffff; font-weight: 500;}
button:hover{background: #c90c2f;}

.btn01{padding: 12px 8px; color:#fff;background:#1E90FF; cursor: pointer; border: 0; margin: 16px 0; border-radius: 4px; 
}
.btn01:visited {
    color: #fff;
}
/*
5847AD fd5922
*/
.clicado:hover{
	background: #bdbdbd;
	color: #fff;
}
.clicado:hover>label{
	background: #bdbdbd;
	
}

.btn02{padding: 15px 30px; color:#fff;background:#32CD32; cursor: pointer; margin: 0 5px; font-family: muli, serif; }
.btn03{height: 4vh; min-height: 37px;}

.btn04{ color:#000; background:#e44b0f; cursor: pointer; }
.btn05{ color:#000; background:#b3aeac; cursor: pointer; }
.btn05:hover{background: #9c9795;}

.btn06{ color:#ffffff; background:#0792E3; cursor: pointer; font-weight: bold; }
.btn06:hover{background: #0792E3;}

.btn07{width: 100%;  border-radius:28px;  border:4px solid #0792E3; color:#ffffff; background:#0792E3; cursor: pointer; margin: 10px 0;}
.btn07:hover{background:#46b6f7; font-weight: 900; border:4px solid #46b6f7; }
.btn08{width: 100%;  border-radius:28px; border:4px solid #0792E3; color:#ffffff; background:none; cursor: pointer; margin: 10px 0;}
.btn08:hover{background: none; font-weight: 900;}


.btn09{
	background:#0792E3;
	border-radius: 4px;
	padding: 2px;
}

.btn10 {
	width: 20%;
	height: 6vh; /* Ajuste a altura usando calc */
	background: #0792E3;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px 0 0 4px;
	font-size: 0.8em;
	margin: 8px 0;
	cursor: pointer;
}
.inputsearch
{width: 100%; height: 3vh; color:#363636; min-height: 27px; border-radius: 4px; border: none; margin:2px 0; border:#d1d1d1 1px solid;}

.inputsearchfront
{width: 100%; height: 3vh; color:#363636; min-height: 27px; border-radius: 4px; border: none ;margin:2px 0;}



.inputs,input[type=Time],input[type=text],input[type=number],input[type=password],select,input[type=submit],input[type=date],input[type=email],input[type=tel]
{width: 100%; height: 6vh; color:#888484; min-height: 27px; border-radius: 3px; background: #ffffff5d;margin:8px 0; border:#d1d1d1 1px solid; padding:0px;}
input[type=submit]{
	height: 6vh;
	border: 0;
	min-height: 27px;
	background:#F3123C;
	margin: 0;
	color:#ffffff;
	cursor: pointer;
	border-radius: 30px;
}
input[type=submit]:hover{background: #c90c2f; font-weight: 600;} 
input[type="radio"] {
	height: 2em;
    -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Chrome, Safari, Opera */
	transform: scale(2);
	cursor: pointer;
	z-index: 0;
}
#input-busca-chamados{
	background-color: rgba(0, 0, 0, 0.164);
	color: #b9b9b9;
	min-height: 3vh;
	margin:1px 0;
}
#btn-busca-chamados{
	background-color: rgba(0, 0, 0, 0.274);
	color: #b9b9b9;
	min-height: 3vh;
	margin:1px 0;
}
form{
	color:
	#000;
}
textarea{
	width: 100%; height: 10vh; color:#363636; min-height: 37px; border-radius: 7px; border: none; background: #f0f0f0;margin:3px 0;resize: vertical; border:#b8b8b8 1px solid;;
}
/* ============================================================ 
	CONTAINERS
*/
.cont-black{background: #c1c1c1;}
.cont-light{background: #fff;}
/* ============================================================ 
	CONTAINERS ESPECIFICOS
*/



.baixado{background: #5bff9a;}
.cancelado{background: #c58484;}
.em-aberto{background: #f6ff72;}
.pendente{background: #ff7272;}
.baixado-parcial{background: #a6beff;}
.baixado-a-maior{background: #5963c4;}



.animate__animated,.sbh{
    overflow-x: hidden;
    overflow-y: hidden;
}
#inputs-form-desnec{display: none;}

.header-img-pizza{
	
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.img-fast-food{
	
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.site-header-img{
	background-image: url(./acets/img_site/bg-header.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.site-header-img-login{
	background-image: url(./acets/img_site/bg-header-login.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.bg-cell-img{
	background-image: url(./acets/img_site/bg-cell.svg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.bg-wpp{
	background-image: url(./acets/img_site/bgwpp.svg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.bg-footer{
	background-image: url(./acets/img_site/bg-footer.svg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.bg-home-tenancy{
	
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.linha{
	border-bottom: 1px solid #e4e4e4;
}
.linham2{
	border-bottom: 2px solid #dfe5f1;
}
.bg-viewperfil{

	background-position: center;
	background-size: cover;
}
.card-user-viewperfil{

	background-position: center;
	background-size: cover;
}
/* ===========================================================
	BOTOES
*/
#menudados{border-radius: 30px 30px 0 0; background: #ABCDFF;}

#container-pesquisa-header{ height: 10vh; max-height: 34px; border:3px solid #377DE1;}
#btn-pesquisa-header{height: 10vh; max-height: 34px; border-radius: 0 30px 30px 0; border: 0; background: rgba(0, 0, 0, 0.0);}
#input-pesquisa-header{height: 10vh; max-height: 34px; border-radius: 30px 0 0 30px; border: 0; background: rgba(0, 0, 0, 0.0); }
.btn-pag{width: 60px; height:27px;background: #e7e7e7; border:none; margin: 5px; border-radius: 6px; cursor: pointer;} 
.btn-pag-itens{width: 30px; height:27px;background: #e7e7e7; border:none; border-radius: 6px; cursor: pointer; margin: 3px;} 
/* ============================================================ 
	CONTAINER FLEX a:active {color: #333;}
*/


/* ============================================================ 
	BASE DE CORES
*/
.b1{background: #000000;}
.b2{background: #fff;}/* branco */
.b3{background: #e0e0e0;}
.b4{background: #363636;}
.b5{background: #0A63A4;} /* cinza claro   */
.b6{background: #303030;} /* menu - li- items*/
.b7{background: #1C1C1C;}
.b8{background:#FAF9F9;}/* branco fundo dasboard*/
.b9{background:#D3FFCB;}/* branco grafico  dasboard*/
.b10{background:#00A7EF;}
.b11{background:#05e2b2; }/* branco azulado submenu*/
/*.b12{background:#00D1FF } azulbackground-color: var(--b12-bg-color);*/
.b12{background-color: var(--b12-bg-color);}
.b13{background:#F3123C; }/* azul*/ 
.b14{background: #ABCDFF;}/* dados*/ 
.b15{background: #EAEAEA;}/* */ 
.b16{background: #EDF0F3;}/* */ 
.b17{background: #FAFAFA;}/* */ 
.b18{background: #dfe9f1;}/* */ 
.b19{background: #414956;}/*Fundo do menu */
.b20{background: #3B414D;}/*Fundo do menu opcoes*/
.b21{background: #F3F3F3;}/*Fundo tela F3F3F3 ECF4FF*/
.b22{background:  #F3F3F3;}/*Fundo Botao submenu clientes*/
.b23{background:  #DDE9F8;}/*Fundo Botao submit*/
.b24{background:  hsla(152, 100%, 80%, 0.952);}
.b25{background: #F4C33E;}
.b26{background: #F8F8FA;}
.b27{background: #F6F6F6;}
.b28{background: #f0f0f0;}
.b29{background: #5847AD;}
.b30{background: #646b79;}
.b31{background: #F5F7F8;}
.b32{background: #AEBAD7;}
.b33{background: #ECEDF2;}

.b34{background: #ECEDF2;}
.b35{background: #494B57;}
.b36{background: #2A2932;}
.b37{background: #F3123C;}

.bt1{background: rgba(43, 93, 187, 0.486);}
.bt2{background: #e8f0f593;}


.b-linear-gray{background: linear-gradient(90deg, #B3B3B3 -7.15%, #DFDEDE 49.58%, #E7E7E7 94.65%);}
.b-linear-blue-h{
	background: rgb(101,26,239);
	background: -moz-linear-gradient(90deg, rgba(101,26,239,1) 0%, rgba(1,165,239,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(101,26,239,1) 0%, rgba(1,165,239,1) 100%);
	background: linear-gradient(90deg, rgba(101,26,239,1) 0%, rgba(1,165,239,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#651aef",endColorstr="#01a5ef",GradientType=1);
}
.b-linear-blue-y{
	background: rgb(129,69,241);
	background: -moz-linear-gradient(176deg, rgba(129,69,241,1) 0%, rgba(1,165,239,1) 100%);
	background: -webkit-linear-gradient(176deg, rgba(129,69,241,1) 0%, rgba(1,165,239,1) 100%);
	background: linear-gradient(176deg, rgba(129,69,241,1) 0%, rgba(1,165,239,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8145f1",endColorstr="#01a5ef",GradientType=1);
}
.b-linear-wellow{background: linear-gradient(128.05deg, #FAE097 48.78%, #F58D7C 92.21%);}
.b-vidro{background: rgba(217, 217, 217, 0.42);backdrop-filter: blur(8px);}


/* ============================================================ 
	NAV E MENU
*/

/* ============================================================ 
		ESCOLHER IMAGEM IMPUT CUSTOMIZADO
*/
.file-input-wrapper {
	position: relative;
	display: inline-block;
	overflow: hidden;
}
.file-input-wrapper img {
	display: block;
	height: 30px;
	margin: 0 auto;
}
.file-input-wrapper .file-input-text {
	display: block;
	font-size: 14px;
	font-weight: bold;
	margin-top: 5px;
	text-align: center;
}
.file-input-wrapper input[type=file] {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	font-size: 20px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
}

/*
DEV ROTINA DE HOME OFFICE
 REUNIÃO INICIAL- VIDEO CHAMADA PARA ALINHAR AS TAREFAS FEITAS NO DIA ANTERIOR
PER PROGRAMMING
code review

*/
@media screen and (max-width: 900px) {
			.celular{flex-direction: column;}
			.celularrow{flex-direction: row;}
			.cellaround{justify-content: space-around;}
			.cell-tamanho{width: 100%;}
			.cell-tamanho10{width: 10%;}
			.cell-tamanho15{width: 15%;}
			.cell-tamanho20{width: 20%;}
			.cell-tamanho75{width: 75%;}
			.cell-tamanho70{width: 70%;}
			.cell-tamanho90{width: 90%;}
			.cell-tamanho-metade{width: 46%;}
			.cell-tamanho-metade30{width: 30%;}
			.cell-tamanho-metade32{width: 32%;}
			.cell-tamanho-metade35{width: 35%;}
			.cell-tamanho-metade50{width: 50%;}
			.cell-tamanho80{width: 80%;}
			.cell-margin5{margin: 5px 0;}
			.cell-padding10v{padding: 0 0 10vh 0;}
			.cell-margin10{margin:  10px 0;}
			.cell-none{display: none;}
			.cell-display-center{display: relative;}

			.cell-padding4{padding: 4px;}
			.cell-paddingcb10px{padding: 10px 0}
			.cell-paddingcb10{padding: 10vh 0}
			.cell-titulo{font-size: 1.9em;}
			.cell-content{font-size: 1em;}
			.cell-pp{padding:0px;}

			.cell-text-algin-center{text-align:center;}

			.cell-texto-p{font-size: 0.8em; font-weight: bold;}
			.cell-card5{height: 5vh;}
			.cell-card{height: 30vh;}
			.cell-card10{height: 10vh;}
			.cell-card20{height: 20vh;}
			.cell-card40{height: 40vh;}

			.imagem-prop-cell{
				max-width:90%;
			    max-height:70px;
			    width: auto;
			    height: auto;
			}
			.cell-posifixedinicio{
				position:relative;
				bottom: auto;
			}
			.cell-posifixedbotton{
				position:fixed;
				bottom: 0px;
				left: 0;
				width: 100%;
				height: 50px;
			}
			.cell-center{justify-content: center;}
			#menu{
				left: -195px;
			}
			.cell-text-smile08{
				font-size: 0.8em;
			}
			.cell-text-smile{
				font-size: 1em;
			}
			.cell-text-smile-weight{
				font-weight: 300;
			}
			.cell-text-title{
				font-size: 2.2em;
			}
			.cell-text-subtitle{
				font-size: 1.8em;
			}
			.roboto{
				font-family:  sans-serif;
				line-height: 24px;
			}
			.cell-left5{
				left: 5%;
			}
			.cell-m0{
				margin: 0;
			}
			.cell-color-black{background: #000;}
			.cell-linha{
				border-bottom: 1px solid #e4e4e4;
			}

}
@media screen and (min-width: 900px) {
	.desktop-none{display: none;}
	.desktop-height-100{height: 100vh;}
}
/*


*/
@media screen and (max-width: 900px) {
	
}